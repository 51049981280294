@import "../../../styles/base/abstracts";

.hbs-cta-list {
  @include list-reset;
  border-top: $border-solid;

  .hbs-cta-list-item {
    &:first-child {
      margin-left: 0;
    }
    .hbs-cta-list-item__link {
      box-shadow: 0 -1px $c-border-light inset;
      transition: $transition;

      &:hover {
        box-shadow: 0 -1px $c-border inset;
      }
    }
  }
}

.hbs-cta-list-item__link {
  @include padding(#{$spacing-sm - 1} x $spacing-sm);
  display: flex;
  justify-content: space-between;
  width: 100%;

  .hbs-cta-link__icon {
    margin-left: $spacing-sm;
  }
}

.hbs-cta-list-label {
  @include small-text;
  color: $c-text-lighter;
  letter-spacing: 0em;
  transform: 0;
  margin-bottom: $spacing-xs;
}
